.dayBorderBox h2 {
    margin-top: 0px;
}

.dayBorderBox {
    padding: 15px 35px 15px 15px;
    margin-bottom: 10px;
}

.repeatDiv {
    color: #10984b;
    padding-top: 8px; 
}
