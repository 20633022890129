@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap');

body {
  margin: 0;
}

@media (max-width: 676px) {
  .logoTitle {
    font-size: 1.2rem!important;
  }
}

@media (max-width: 576px) {
  .logoTitle {
    font-size: 1.0rem!important;
  }
}