.mealItem {
    background-color: #F6EDD9;
    padding: 10px;
    margin-bottom: 10px;
}

.dayBorderBox {
    padding: 15px;
    margin: 10px;
    
}

.btnRemove {
    float: right;
    color: red;
}