.nav {
  height: 80px;
  background: #10984b;
  padding: 16px 20px;
  color: #FFF;
}

.nav-right {
  text-align: right;
}

.vertMiddleLeft {
  padding: 5px 0;
}

.vertMiddleRight {
  padding: 14px 0;
}

.logoTitle {
  font-family: 'Londrina Solid', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  vertical-align: middle;
}

.vertMiddleLeft img {
  height: 40px;
  vertical-align: middle;
  margin-right: 8px;
}

.content {
  padding: 10px;
}